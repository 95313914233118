import React from 'react';
import { Link } from 'react-router-dom';
import { Menu} from 'antd';

const ProfileMenu = (userId, isPermissionLoaded, hasPermission) => (
    <Menu>
        {isPermissionLoaded && hasPermission('core:profileMenu:dashboard') && (
            <Menu.Item key="0">
                <Link to={'/admin/dashboard'}>Dashboard</Link>
            </Menu.Item>
        )}
        {isPermissionLoaded && hasPermission('core:profileMenu:upload') && (
            <Menu.Item key="1" className="ant-dropdown-menu-item ant-dropdown-menu-item-only-child">
                <Link to={'/upload/trade/fiber'}>Upload</Link>
            </Menu.Item>
        )}
        <Menu.Item key="2">
            <Link to={'/my-account'}>My Account</Link>
        </Menu.Item>
        <Menu.Item key="3">
            <Link to={`/profile/${userId}`}>My Profile</Link>
        </Menu.Item>
        <Menu.Item key="4">
            <Link to={`/profile/news-feed/${userId}`}>News Feed</Link>
        </Menu.Item>
        <Menu.Item key="5">
            <Link to={'/change-password'}>Change Password</Link>
        </Menu.Item>
        <Menu.Item key="7">
            <Link to={'/auth/logout'}>Logout</Link>
        </Menu.Item>
    </Menu>
);

export default ProfileMenu;
