import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/follow`
const endpoints = {
    list: () => `${RESOURCE_ENDPOINT}`,
    checkFlowStatus: () => `${RESOURCE_ENDPOINT}/check-flow-status`,
    create: () => `${RESOURCE_ENDPOINT}`,
    delete: () => `${RESOURCE_ENDPOINT}`,
    getFollowerByUserId: () => `${RESOURCE_ENDPOINT}/count-followers`,
}

export default class FollowApi {
    public list = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.list();
        return HttpService.get(url, params, headers);
    }

    public checkFlowStatus = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.checkFlowStatus();
        return HttpService.get(url, params, headers);
    }

    public create = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.create();
        return HttpService.post(url, payload, params, headers);
    }

    public delete = (params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.delete();
        return HttpService.delete(url, params, headers);
    }

    public getFollowerByUserId = ( params = {}, headers = {} ): AxiosPromise<any> => {
        const url = endpoints.getFollowerByUserId();
        return HttpService.get( url, params, headers );
    }

}