import {
  SET_LOADING,
  SET_ERROR,
  LOADED_USER,
  LOADED_GROUP,
  LOADED_ROLE,
  LOADED_WORKSPACE,
  LOADED_ORGANIZATION,
  LOADED_SCOPE,
  CLEAR_ALL_AUTH_STATE,
  SHOW_ALERT,
  HIDE_ALERT,
  LOADED_AUTH_STATE,
  CLEAR_AUTH_STATE,
  LOADED_TOKEN,
  REMOVE_TOKEN,
  LOADED_SUPPLIER_REGISTER_ENTITY,
} from './auth.types';
import {StorageService} from "../../services";

export default (state, action) => {
  switch (action.type) {
    case LOADED_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        profileImage: action.payload.profileImage,
        userId: action.payload.userId,
        loading: false,
        error: false,
      };

    case CLEAR_AUTH_STATE:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        userId: null,
        scopes: null,
        loading: false,
        error: null,
      };

    case CLEAR_ALL_AUTH_STATE:
      return {
        ...state,
        user: null,
        profileImage: null,
        userId: null,
        scopes: null,
        userGroup: null,
        group: null,
        groupId: null,
        userRole: null,
        role: null,
        roleId: null,
        workspace: null,
        workspaceId: null,
        organization: null,
        organizationId: null,
        loading: false,
        error: null,
      };

    case LOADED_USER:
      return {
        ...state,
        user: action.payload.user,
        profileImage: action.payload.profileImage,
        userId: action.payload.userId,
      };

    case LOADED_SCOPE:
      return {
        ...state,
        scopes: action.payload.scopes,
      };

    case LOADED_GROUP:
      return {
        ...state,
        userGroup: action.payload.userGroup,
        groupId: action.payload.groupId,
        group: action.payload.group,
      };

    case LOADED_ROLE:
      return {
        ...state,
        userRole: action.payload.userRole,
        roleId: action.payload.roleId,
        role: action.payload.role,
      };

    case LOADED_WORKSPACE:
      return {
        ...state,
        workspaceId: action.payload.workspaceId,
        workspace: action.payload.workspace,
      };

    case LOADED_ORGANIZATION:
      return {
        ...state,
        organizationId: action.payload.organizationId,
        organization: action.payload.organization,
        loading: false,
        error: null,
      };

    case LOADED_SUPPLIER_REGISTER_ENTITY:
      return {
        ...state,
        supplierRegisterEntity: action.payload.supplierRegisterEntity,
        isLoadSupplierRegisterEntity: action.payload.isLoadSupplierRegisterEntity,
      };

    case LOADED_TOKEN:
      StorageService.setAccessToken(action.payload.accessToken);
      StorageService.setRefreshToken(action.payload.refreshToken);
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      };

    case REMOVE_TOKEN:
      StorageService.removeAccessToken();
      StorageService.removeRefreshToken();
      return {
        ...state,
        isAuthenticated: false,
        accessToken: null,
        refreshToken: null,
      };

    case SET_LOADING:
      return {
        ...state,
        loading: action.payload.loading,
      };

    case SHOW_ALERT:
      return {
        ...state,
        alertText: action.payload.alertText,
        alertType: action.payload.alertType,
        alertVisible: true,
      };

    case HIDE_ALERT:
      return {
        ...state,
        alertText: '',
        alertType: '',
        alertVisible: false,
      };

    case SET_ERROR:
      return {
        ...state,
        error: action.payload.error,
      };

    default:
      return state;
  }
};