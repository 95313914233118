export const SET_ERROR = 'SET_ERROR';
export const SET_LOADING = 'SET_LOADING';
export const LOADED_USER = 'LOADED_USER';
export const LOADED_GROUP = 'LOADED_GROUP';
export const LOADED_ROLE = 'LOADED_ROLE';
export const LOADED_WORKSPACE = 'LOADED_WORKSPACE';
export const LOADED_ORGANIZATION = 'LOADED_ORGANIZATION';
export const LOADED_SCOPE = 'LOADED_SCOPE';
export const LOADED_TOKEN = 'LOADED_TOKEN';
export const REMOVE_TOKEN = 'REMOVE_TOKEN';
export const CLEAR_ALL_AUTH_STATE = 'CLEAR_ALL_AUTH_STATE';
export const SHOW_ALERT = 'SHOW_ALERT';
export const HIDE_ALERT = 'HIDE_ALERT';
export const LOADED_AUTH_STATE = 'LOADED_AUTH_STATE';
export const CLEAR_AUTH_STATE = 'CLEAR_AUTH_STATE';
export const LOADED_SUPPLIER_REGISTER_ENTITY = 'LOADED_SUPPLIER_REGISTER_ENTITY';
