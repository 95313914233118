import React from "react";
import { node } from "prop-types";

import AuthProvider from "./auth/auth.context";
import SettingProvider from "./setting/setting.context";
import DataProvider from "./data/data.context";

import { I18nextProvider } from 'react-i18next'
import i18n from './../i18n/i18n'

const ContextProvider = ({ children }) => {
    return (
        <I18nextProvider i18n={i18n}>
            <AuthProvider>
                <SettingProvider>
                    <DataProvider>
                        {children}
                    </DataProvider>
                </SettingProvider>
            </AuthProvider>
        </I18nextProvider>
    );
};

ContextProvider.propTypes = {
    children: node.isRequired
};

export default ContextProvider;