export default class EnumUtils {

    public getEnumKeys<T>(enumObject: T): string[] {
        const retArray = new Array<string>();
        Object.keys(enumObject).filter(key => isNaN(Number(key))).forEach(
            key => {
                retArray.push(key);
            });
        return retArray;
    }

    public getEnumValues<T>(enumObject: T): (T[keyof T] )[] {
        const retArray = this.getEnumKeys(enumObject).map((key: string) => enumObject[key]);
        return retArray;
    }

    public getEnumKey(enumObject: any, value: any): string {
        for (const key in enumObject) {
            if (value === enumObject[key]) {
                return key
            }
        }
        return ''
    }

    public getEnumValue = (keyString: string, enumObject: any) => {
        const keyArray = keyString.split('.');
        const enumValue = keyArray.reduce((acc: {}, curr) => {
            return acc[curr] || {};
        }, enumObject) as string;
        return enumValue;
    };

}