import {FETCH_TEST, FETCHED_TEST, FETCHING_TEST} from "./types";

const initialState = {
    test: {},
    isFetching: false
};

export default (state = initialState, action) => {
    switch (action.type) {
        case FETCH_TEST:
            return {...state, test: action.payload};

        case FETCHING_TEST:
            return {...state, isFetching: true};

        case FETCHED_TEST:
            return {...state, isFetching: false};

        default:
            return state
    }
};