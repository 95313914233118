import React from 'react'
import {Switch, Tag} from "antd";

export default class CommonUtils {
    public getStatusNameByLevel = (status:any = 0) => {
        if (status === true) {
            return <Tag color="success">Active</Tag>
        }
        if (status === 1) {
            return <Tag color="success">Active</Tag>
        }
        else if (status === false) {
            return <Tag color="default">Inactive</Tag>
        }
        else if (status === 0) {
            return <Tag color="default">Inactive</Tag>
        }
    };

    public displaySwitchToggleBtn = (entity: any, status, callback: (checked, entity) => void) => {
        if (status) {
            return <Switch size="small" checked={true} onChange={(checked) => callback(checked, entity)} />
        }
        else {
            return <Switch size="small" checked={false} onChange={(checked) => callback(checked, entity)} />
        }
    };
}
