export default {
  title: 'Rentvax',
  header: 'Rate your rent',
  placeholder: 'Prague',
  search: 'Find address',
  home: 'Home',
  addReview: 'Add review',
  myReviews: 'My reviews',
  logout: 'Log out',
  login: 'Log in',
  nearestReviews: 'The nearest reviews for address',
  loginToRate: 'You must log in to add review',
  showOnMap: 'Show on Google Maps',
  moreReviews: 'MORE REVIEWS',
  user: 'User',
  edit: 'Edit',
  delete: 'Delete',
  yes: 'Yes',
  no: 'No',
  addressError:
    'Please, choose exact address (including address number). E.g. write Roosveltova 42 and choose it from the list',
  yourReview: 'Your review',
  required: 'Required',
  rateError: 'Invalid rating value',
  maxLengthError: 'Max 5 000 characters',
  reviewChanged: 'Review edited',
  reviewCreated: 'Review created',
  rating: 'Rating',
  review: 'Review',
  changeReview: 'Change review',
  createReview: 'Create review',
  notFound: 'Page does not exist',
  cs: 'CZ',
  en: 'EN',
  queryError:
    'Rentvax used up free daily limit for Google Maps. Limit will restore tomorrow.',
  infoAnonymousTitle: 'Anonymous',
  infoAnonymousText:
    'No Google analytics, no tracking software. We do not publish your email address or username.',
  infoOpenTitle: 'Open source',
  infoOpenText:
    'Source code is open to anyone who wants to check or contribute. Go to',
  infoDonateTitle: 'Ads free',
  infoDonateText:
    'Rentvax uses free tiers of Google services. We depend on donations to upgrade our services -',
  donate: 'Donation',
}
