import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/chat`
const endpoints = {
    chatList: (userId: any) => `${RESOURCE_ENDPOINT}/chat-list/${userId}`,
    contactList: (userId: any) => `${RESOURCE_ENDPOINT}/contact-list/${userId}`,
    chatRoomHeaderInfo: (roomId:any, userId:any) => `${RESOURCE_ENDPOINT}/chat-room-header-info/${roomId}/${userId}`,
}

export default class ChatApi {
    public getChatList = (userId: any): AxiosPromise<any> => {
        const url = endpoints.chatList(userId);
        return HttpService.get(url);
    }

    public getContactList = (userId: any): AxiosPromise<any> => {
        const url = endpoints.contactList(userId);
        return HttpService.get(url);
    }

    public getChatRoomHeaderInfo = (roomId:any, userId:any): AxiosPromise<any> => {
        const url = endpoints.chatRoomHeaderInfo(roomId, userId);
        return HttpService.get(url);
    }
}