import React from 'react';
import { Switch } from 'react-router-dom';
import {MainLayout, AdminLayout, UploadLayout} from "../theme";
import PublicRoute from "./PublicRoute";
import Login from "../modules/Auth/components/Login/Login.controller";
const AdminRoutes = React.lazy(() => import('../routes/AdminRoutes'));
const UploadRoutes = React.lazy(() => import('../routes/UploadRoutes'));
const AuthRoutes = React.lazy(() => import('./../modules/Auth/AuthRoutes'));
const PagesRoutes = React.lazy(() => import('./../modules/Pages/PagesRoutes'));
const SettingRoutes = React.lazy(() => import('../modules/Setting/SettingRoutes'));
const TradeRoutes = React.lazy(() => import('../modules/Trade/TradeRoutes'));
const ShopRoutes = React.lazy(() => import('../modules/Shop/ShopRoutes'));
const ProfileRoutes = React.lazy(() => import('../modules/Profile/ProfileRoutes'));
const ChatRoutes = React.lazy(() => import('../modules/Chat/ChatRoutes'));
const UikitRoutes = React.lazy(() => import('../components/Uikit/UikitRoutes'));

const LandingPage = React.lazy(() => import('../modules/Pages/components/LandingPage/LandingPage'));
const MyAccount = React.lazy(() => import('./../modules/Auth/components/MyAccount/MyAccount.controller'));
const ChangePassword = React.lazy(() => import('./../modules/Auth/components/ChangePassword/ChangePassword.controller'));
const AccessDenied = React.lazy(() => import('../components/Error/AccessDenied/AccessDenied'));
const NotFound = React.lazy(() => import('../components/Error/NotFound/NotFound'));

const Routes: React.FC = () => {
  return (
      <Switch>
          <PublicRoute layout={MainLayout} exact path="/" component={Login} />
          <PublicRoute layout={AdminLayout} path="/admin" component={AdminRoutes} />
          <PublicRoute layout={UploadLayout} path="/upload" component={UploadRoutes} />
          <PublicRoute layout={MainLayout} path="/page" component={PagesRoutes} />
          <PublicRoute layout={MainLayout} path="/auth" component={AuthRoutes} />
          <PublicRoute layout={MainLayout} path="/trade" component={TradeRoutes} />
          <PublicRoute layout={MainLayout} path="/shop" component={ShopRoutes} />
          <PublicRoute layout={MainLayout} path="/profile" component={ProfileRoutes} />
          <PublicRoute layout={MainLayout} path="/setting" component={SettingRoutes} />
          <PublicRoute layout={MainLayout} path="/apps" component={ChatRoutes} />
          <PublicRoute layout={MainLayout} path="/uikit" component={UikitRoutes} />

          <PublicRoute layout={MainLayout} exact path="/my-account" component={MyAccount} />
          <PublicRoute layout={MainLayout} exact path="/change-password" component={ChangePassword} />
          <PublicRoute layout={MainLayout} exact path="/access/denied" component={AccessDenied} />
          <PublicRoute layout={MainLayout} path="/page-not-found" component={NotFound} />
      </Switch>
  );
};

export default Routes;