const initialState = {
  loading: true,
  isLoaded: false,
  isAuthenticated: null,
  accessToken: localStorage.getItem('accessToken'),
  refreshToken: localStorage.getItem('refreshToken'),
  user: null,
  profileImage: null,
  userId: null,
  userGroup: null,
  groupId: null,
  group: null,
  userRole: null,
  roleId: null,
  role: null,
  scopes: [] as any,
  workspaceId: null,
  workspace: null,
  organizationId: null,
  organization: null,
  isLoadSupplierRegisterEntity: false,
  supplierRegisterEntity: [] as any,
  error: null,

  dispatchAuth: (action) => null,
  loadAuthState: () => null,
  clearAuthState: () => null,
  setLoading: (isShow) => null,
};

export default initialState;
