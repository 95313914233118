import { combineReducers } from "redux";

import TestReducer from './test/reducer';
import AuthReducer from '../modules/Auth/redux';

const rootReducer = combineReducers({
    testSore: TestReducer,
    authStore: AuthReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;