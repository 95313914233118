export default {
    'component.accountInformation.title': 'Account',
    'component.accountInformationEdit.title': 'Edit account',
    'component.accountInformationEdit.field.firstName': 'First name',
    'component.accountInformationEdit.field.lastName': 'Last name',
    'component.accountInformationEdit.field.birthDate': 'Birth date',
    'component.accountInformationEdit.field.phoneNumber': 'Phone number',
    'component.accountInformationEdit.field.birthPlace': 'Birtht place',
    'component.accountInformationEdit.field.socialSecurityNumber': 'Social security number',
    'component.accountInformationEdit.field.taxNumber': 'Tax number',
    'component.accountInformationEdit.field.mothersMaidenName': 'Mother\'s name'
};