import './Login.style.scss'

import React, { FC, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Form, Input } from 'antd'
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons'

import images from '../../../../assets/images'
import { IProps } from './Login.types'
import { LAYOUT_FORM_ITEM_SINGLE } from '../../../../utils/Grid'

const LoginView: FC<IProps> = props => {
    const [passwordShow, setPasswordShow] = useState(false)
    const {formRef, rules, initialValues, loading, isSubmitting, handleChange, handleSubmit, handleSubmitFailed} = props;
    return (
        <div className="admin-loginpage">
            <div className="admin-login-box">
                <div className="adminlogin-logo">
                    <img  src={images.layout.adminloginLogo} alt="Adminlogin Logo" />
                    <h2>Admin</h2>
                </div>

                <Form
                    {...LAYOUT_FORM_ITEM_SINGLE.normal}
                    form={formRef}
                    name="loginForm"
                    scrollToFirstError={true}
                    initialValues={initialValues}
                    onValuesChange={handleChange}
                    onFinish={handleSubmit}
                    onFinishFailed={handleSubmitFailed}
                >
                    <Form.Item
                        name="email"
                        rules={rules.email}
                    >
                        <Input type="text" placeholder="Email"  />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={rules.password}
                    >
                        <div className="password-icon-area">
                        <Input type={passwordShow ? "text" : "password"} placeholder="Password" />
                        <button type="button" onClick={() => setPasswordShow(!passwordShow)}>

                            {passwordShow ? <EyeOutlined /> : <EyeInvisibleOutlined /> }
                        
                        </button>
                        </div>
                        
                        
                    </Form.Item>
                    <Form.Item {...LAYOUT_FORM_ITEM_SINGLE.tail}>
                        <Button type="primary" htmlType="submit" className="submit-loading-button" disabled={isSubmitting} loading={loading}>
                            Login
                        </Button>
                    </Form.Item>
                    {/* <div className="row-admin-login row-admin-extratext">
                        <span className="admin-create-newaccountlink">
                            <Link to={'/auth/register'}>Create new account</Link>
                        </span>
                        <span className="admin-ortext">or</span>
                        <span className="admin-forgetyour-passwordlink">
                            <Link to={'/auth/forgot-password'}>Forgot your password</Link>
                        </span>
                    </div> */}
                </Form>
            </div>
        </div>
    );
}

export default React.memo(LoginView);
