import { ActionType, Action, IState } from './Auth.types';

const initialState: IState = {
    resetPasswordLink: '',
    accountActivationLink: '',
};

export function authReducer(state: IState = initialState, action: Action) {
    switch (action.type) {
        case ActionType.SET_RESET_PASSWORD_LINK:
            return {
                ...state,
                resetPasswordLink: action.payload,
            };
        case ActionType.SET_ACCOUNT_ACTIVATION_LINK:
            return {
                ...state,
                accountActivationLink: action.payload,
            };
        default:
            return state;
    }
};