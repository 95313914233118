export default {
  title: 'Rentvax',
  header: 'Ohodnoťte svůj podnájem',
  placeholder: 'Praha',
  search: 'Najděte adresu',
  home: 'Domů',
  addReview: 'Přidat recenzi',
  myReviews: 'Moje recenze',
  logout: 'Odhlásit se',
  login: 'Přihlásit se',
  nearestReviews: 'Recenze nejblíž adresy',
  loginToRate: 'Pro přidání recenze se musíte přihlásit',
  showOnMap: 'Zobraz na Google Maps',
  moreReviews: 'VÍCE RECENZÍ',
  user: 'Uživatel',
  edit: 'Editovat',
  delete: 'Smazat',
  yes: 'Ano',
  no: 'No',
  addressError:
    'Vyberte prosím přesnou adresu s číslem popisným - např. napište Roosveltova 42 a poté vyberte adresu ze seznamu',
  yourReview: 'Vaše recenze',
  required: 'Povinné',
  rateError: 'Neplatná hodnota hodnocení',
  maxLengthError: 'Max 5 000 znaků',
  reviewChanged: 'Recenze změněna',
  reviewCreated: 'Recenze vytvořena',
  rating: 'Hodnocení',
  review: 'Recenze',
  changeReview: 'Změnit recenzi',
  createReview: 'Vytvořit recenzi',
  notFound: 'Stránka neexistuje',
  cs: 'CZ',
  en: 'EN',
  queryError:
    'Rentvax vyčerpal denní limit pro neplacenou verzi Google Maps. Zítra se limit opět obnoví.',
  infoAnonymousTitle: 'Anonymní',
  infoAnonymousText:
    'Žádné Google Analytics nebo sledovací software. Nezveřejňujeme Váš email ani login.',
  infoOpenTitle: 'Open source',
  infoOpenText:
    'Zdrojový kód je otevřený pro kohokoliv, kdo ho chce zkontrolovat nebo se přidat k vývoji -',
  infoDonateTitle: 'Bez reklam',
  infoDonateText:
    'Rentvax používá Google služby v bezplatném režimu. Pro rozšíření našich služeb se spoléháme na dary -',
  donate: 'Darujte',
}
