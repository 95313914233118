import {
  UPDATE_USER_CONTEXT,
  UPDATE_ROLE_CONTEXT,
} from './data.types';

export default (state, action) => {
  switch (action.type) {
    case UPDATE_USER_CONTEXT:
      return {
        ...state,
        userContext: {
          ...state.userContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    case UPDATE_ROLE_CONTEXT:
      return {
        ...state,
        roleContext: {
          ...state.roleContext,
          items: action.payload.items,
          isLoading: action.payload.isLoading,
          isLoaded: action.payload.isLoaded,
          totalCount: action.payload.totalCount,
        }
      };

    default:
      return state;
  }
};