// export const DEV = process.env.NODE_ENV === 'development';
export const ENV_PROD = false;
export const ENV_DEV = true;
export const ENV_TEST = false;

export const BASE_URL = 'https://itagama.com';
// export const API_URL = 'http://uat.api.itagama.iwork.pw/';
// export const API_URL = 'http://localhost:8000/';
export const API_URL = 'https://apiprod.itagama.com/';
// export const CDN_URL = 'http://uat.cdn.itagama.iwork.pw/';
export const CDN_URL = 'https://cdnprod.itagama.com/';
export const MEDIA_SOURCE = 'https://cdnprod.itagama.com/image/';
export const WEB_SOCKET_URL = 'http://54.254.218.30:5047/itagma';
export const NODE_API = 'https://nodeapp.itagama.com/';

export const MESSENGER_NODE_API = 'https://nodemess.itagama.com/'

export const CLIENT_ID = '8ee9537b-63a7-3495-9de9-b1c3eb38c004';
export const CLIENT_SECRET = '67082845084a1485c64709c8d56e50a2';

export const LOG_ENABLED = true;

export const AUTH_SERVER_PREFIX = 'auth';
export const TRADE_SERVER_PREFIX = 'trade';
export const BLOG_SERVER_PREFIX = 'blog';
export const SHOP_SERVER_PREFIX = 'shop';
export const JOB_SERVER_PREFIX = 'job';
export const CDN_SERVER_PREFIX = 'cdn';
export const CORE_SERVER_PREFIX = 'core';





export const MESSENGER_SERVER_PREFIX = 'http://localhost:8080'