import React, {FC, useState, useContext, useEffect} from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import LoginView from './Login.view';
import {AuthContext} from "../../../../context/auth/auth.context";
import { useForm } from '../../../../hooks/useForm';
import { rules } from './Login.validate';
import {LOADED_TOKEN} from "../../../../context/auth/auth.types";
import { Message } from "../../../../utils";
import {OauthApi} from "../../../../api";

const Login: FC = () => {
    const history = useHistory();
    const { isAuthenticated, dispatchAuth, loadAuthState} = useContext(AuthContext)
    const [loading, setLoading] = useState(false);
    const { formRef, initialValues, setErrors, isSubmitting, setIsSubmitting, handleChange, handleSubmitFailed } = useForm({});
    const [userId, setUserId] = useState(71);
    useEffect(() => {
        if (isAuthenticated === true) {
            setLoading(false);
            Message.success('Well done! You successfully logged in to this website');
            // history.push(`/profile/news-feed/${userId}`);
            history.push("/admin/dashboard")
        }
    }, [isAuthenticated]);

    const handleSubmit = (values: any): void => {
        setLoading(true);
        setIsSubmitting(true);
        const payload = {
            email: values.email,
            password: values.password,
        }
        OauthApi.login(payload)
            .then(res => {
                dispatchAuth({
                    type: LOADED_TOKEN,
                    payload: {
                        accessToken: res.data.access_token,
                        refreshToken: res.data.refresh_token,
                    }
                });
                setUserId(res.data.user.id)
                loadAuthState();
                setLoading(false);
                setIsSubmitting(false);
            })
            .catch(err => {
                if (err?.status === 409) {
                    setErrors(err.data);
                }
                else if (err?.status === 412) {
                    setErrors(err.data);
                }
                else if (err?.status === 422) {
                    Message.error(err.data)
                }
                else {
                    Message.error('A network error occurred. Please try again later.');
                }
                setLoading(false);
                setIsSubmitting(false);
            });
    }

    if (isAuthenticated === false) {
        return (
            <LoginView
                formRef={formRef}
                initialValues={initialValues}
                rules={rules}
                loading={loading}
                isSubmitting={isSubmitting}
                handleChange={handleChange}
                handleSubmit={handleSubmit}
                handleSubmitFailed={handleSubmitFailed}
            />
        );
    }

    return (
        <></>
    );
}

export default withRouter(Login);
