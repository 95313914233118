import { useState, useEffect } from 'react';
import { Form } from 'antd';

export const useForm = (initialState = {}) => {
  const initialValues = initialState;
  const [ errors, setErrors ] = useState<any>({});
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ formRef ] = Form.useForm();

  useEffect(() => {
    let errorArray:any = [];
    if (Object.keys(errors).length) {
      for (const errorKey of Object.keys(errors)) {
        let errorObj = {
          name: errorKey,
          errors: [errors[errorKey]]
        }
        errorArray.push(errorObj);
      }
      formRef.setFields(errorArray)
    }
  }, [errors]);

  const handleChange = (changedValues: any) => {
  };

  const handleSubmitFailed = (values: any): void => {
    setIsSubmitting(false);
  }

  const resetForm = () => {
    formRef.resetFields();
  };

  return {
    formRef,
    initialValues,
    errors,
    setErrors,
    isSubmitting,
    setIsSubmitting,
    resetForm,
    handleChange,
    handleSubmitFailed,
  };
};
