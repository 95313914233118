import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import UploadSidebar from "../../components/UploadSidebar/UploadSidebar";
import '../../../../assets/scss/style.scss';
import './UploadLayout.scss';
import { Col, Row } from 'antd';

interface Props {
    children: ReactNode;
    title?: string;
}

export const UploadLayout: React.FC<Props> = (props: Props) => {
    return (
        <div className="app-wrapper app-upload-wrapper">
            <Header />
            <div className="main-layout">
                <div className="content-wrapper">
                    <div className="main-content">
                        <Row>
                            <Col xs={24} xl={24}>
                                <div className="dashboard-container">
                                    <div className="dashboard-content uploadModule">
                                        <div className="dashboard-left-sidebar">
                                            <div className="dashboard-left-navigation">
                                                <UploadSidebar />
                                            </div>
                                        </div>
                                        <div className="dashboard-body-content">
                                            {props.children}
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}