import React from "react";
import { DashboardOutlined, UserOutlined, FileProtectOutlined, NotificationOutlined } from '@ant-design/icons';

export const LeftSidebarMenu = [
    {
        type: 'item',
        title: 'Dashboard',
        permission: '',
        permissions:[1, 2, 7],
        link: { to: '/admin/dashboard', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <DashboardOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Users',
        permissions:[1,],
        permission: '',
        link: { to: '/admin/user-management/user', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <UserOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Post Approval',
        permissions:[1, 2,],
        permission: '',
        link: { to: '/admin/post-approval', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <FileProtectOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Promotion',
        permission: '',
        permissions:[1, 7],
        link: { to: '/admin/promotion', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <NotificationOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Roles',
        permissions:[1, 2, 7],
        permission: 'auth:role:list',
        link: { to: '/admin/user-management/role', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Permissions',
        permissions:[1, 2, 7],
        permission: 'auth:role:list',
        link: { to: '/admin/permission/role-permission', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Field Permissions',
        permissions:[1, 2, 7],
        permission: 'auth:role:list',
        link: { to: '/admin/permission/field-permission', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'User Additional Permissions',
        permission: 'auth:role:list',
        permissions:[1, 2, 7],
        link: { to: '/admin/permission/user-additional-permission', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Resources',
        permission: 'auth:role:list',
        permissions:[1, 2, 7],
        link: { to: '/admin/permission/resource', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Scopes',
        permission: 'auth:role:list',
        link: { to: '/admin/permission/scope', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
];
