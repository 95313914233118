import React, {createContext, useReducer, useEffect   } from 'react'
import initialState from './setting.state';
import settingReducer from './setting.reducer';
import {
  UPDATE_MENU_KEY,
  UPDATE_SETTING,
  UPDATE_TAB_GROUP,
} from './setting.types';
import {OptionApi} from "../../api";

export const SettingContext = createContext(initialState);

export const SettingProvider = ({ children }) => {
  const [state, dispatchSetting] = useReducer(settingReducer, initialState);

  useEffect(() => {
    loadSetting();
  }, []);

  const loadSetting = async () => {
    OptionApi.load()
        .then(res => {
          dispatchSetting({
            type: UPDATE_SETTING,
            payload: {
              fields: res.data,
            }
          });
        });
  }


  const updateSettings = (values) => {
    dispatchSetting({
      type: UPDATE_SETTING,
      payload: {
        fields: values,
      }
    });
  };

  const setHeaderTabGroup = (tabGroup) => {
    dispatchSetting({
      type: UPDATE_TAB_GROUP,
      payload: {
        headerTabGroup: tabGroup,
      }
    });
  };

  const setActiveMenuKey = (key) => {
    dispatchSetting({
      type: UPDATE_MENU_KEY,
      payload: {
        activeMenuKey: key,
      }
    });
  };

  return (
    <SettingContext.Provider value={{ ...state, updateSettings, setHeaderTabGroup, setActiveMenuKey}}>
      {children}
    </SettingContext.Provider>
  );
};

export default SettingProvider;
