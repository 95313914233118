import React, {FC, useState} from 'react'
import InvitePeopleModal from './InvitePeople.modal';
import { useForm } from '../../../../hooks/useForm';
import { rules } from './InvitePeople.validate';
import { Message } from "../../../../utils";
import {OauthApi} from "../../../../api";
import '../../styles/Common.module.scss';
import './InvitePeople.style.scss';

const InvitePeople: FC<any> = props => {
    const { formRef, initialValues, isSubmitting, handleChange, handleSubmitFailed, resetForm } = useForm({});

    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);

    const handleSubmit = (values: any): void => {
        setLoading(true);
        setVisible(false);
        const payload = {
            email: values.email,
        }
        OauthApi.invitePeople(payload)
            .then(res => {
                Message.success('Send invitation successfully.');
                resetForm();
                setLoading(false);
            })
            .catch(err => {
                console.error("error", err);
                Message.error('A network error occurred. Please try again later.');
                setLoading(false);
            });
    }

    const showModal = () => {
        setVisible(true);
    };

    const hideModal = (e) => {
        setVisible(false);
    };

    return (
        <InvitePeopleModal
            formRef={formRef}
            visible={visible}
            initialValues={initialValues}
            rules={rules}
            loading={loading}
            isSubmitting={isSubmitting}
            handleChange={handleChange}
            showModal={showModal}
            hideModal={hideModal}
            handleSubmit={handleSubmit}
            handleSubmitFailed={handleSubmitFailed}
        />
    );
}

export default React.memo(InvitePeople);
