import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CDN_SERVER_PREFIX}/image`
const endpoints = {
    getById: (fileId: any) => `${RESOURCE_ENDPOINT}/${fileId}`,
}

export default class ImageApi {
    public getById = (fileId: any): AxiosPromise<any> => {
        const url = endpoints.getById(fileId);
        return HttpService.get(url);
    }
}