import React, { FC, Fragment } from 'react'
import {Modal, Button, Form, Input} from 'antd';
import {LAYOUT_FORM_ITEM_SINGLE} from "../../../../utils/Grid";
import images from "../../../../assets/images";

const InvitePeopleModal: FC<any> = props => {
    const {formRef, rules, initialValues, loading, isSubmitting, handleChange, handleSubmit, handleSubmitFailed} = props;
    const {visible, showModal, hideModal} = props;

    return (
        <Fragment>
            <span onClick={showModal}><img src={images.icons.invitePeopleIcon} alt="Invite People" /></span>
            <Modal
                className="review-and-rating-modal"
                title="Invite People"
                centered
                visible={visible}
                onCancel={hideModal}
                footer={null}
            >
                <div className="modal-review">
                    <Form
                        {...LAYOUT_FORM_ITEM_SINGLE.normal}
                        form={formRef}
                        name="reviewAndRatingForm"
                        scrollToFirstError={true}
                        initialValues={initialValues}
                        onValuesChange={handleChange}
                        onFinish={handleSubmit}
                        onFinishFailed={handleSubmitFailed}
                    >
                        <Form.Item
                            name="email"
                            rules={rules.email}
                        >
                            <Input placeholder="Email Address" />
                        </Form.Item>

                        <Form.Item>
                            <div className="review-and-rating-modal-button">
                                <Button type="primary" htmlType="submit" className="submit-loading-button" disabled={isSubmitting} loading={loading}>
                                    Send Invitation
                                </Button>
                            </div>
                        </Form.Item>

                    </Form>
                </div>
            </Modal>
        </Fragment>
    );
}
export default React.memo(InvitePeopleModal);