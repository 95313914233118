import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.NODE_API}`
const endpoints = {
    getInfo: () => `${RESOURCE_ENDPOINT}/metadata/getValue`,
}

export default class NodeApi {
    public getInfo = (data:any): AxiosPromise<any> => {
        const url = endpoints.getInfo();
        return HttpService.post(url, data);
    }
}