import { AxiosPromise } from "axios";
import { CONSTANT_CONFIG } from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/review-and-rating`;
const endpoints = {
  list: () => `${RESOURCE_ENDPOINT}/list`,
  getReviewAndRating: () => `${RESOURCE_ENDPOINT}`,
  create: () => `${RESOURCE_ENDPOINT}`,
  delete: (id) => `${RESOURCE_ENDPOINT}/${id}`,
  getReviewAndRatingByUserId: () => `${RESOURCE_ENDPOINT}/user-review-rating`,
  update: (id: Number) => `${RESOURCE_ENDPOINT}/${id}`,
};

export default class ReviewAndRatingApi {
  public list = (params = {}, headers = {}): AxiosPromise<any> => {
    const url = endpoints.list();
    return HttpService.get(url, params, headers);
  };

  public getReviewAndRating = (
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.getReviewAndRating();
    return HttpService.get(url, params, headers);
  };

  public deleteRating = (id: any): AxiosPromise<any> => {
    const url = endpoints.delete(id);
    return HttpService.delete(url);
  };

  public create = (
    payload = {},
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.create();
    return HttpService.post(url, payload, params, headers);
  };

  public getReviewAndRatingByUserId = (
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.getReviewAndRatingByUserId();
    return HttpService.get(url, params, headers);
  };

  public update = (
    id: any,
    payload = {},
    params = {},
    headers = {}
  ): AxiosPromise<any> => {
    const url = endpoints.update(id);
    return HttpService.put(url, payload, params, headers);
  };
}
