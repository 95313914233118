import React, { FC } from 'react';
import './Footer.style.scss';
import images from '../../../../assets/images';

const Footer: FC<any> = (props) => {
  return (
    <footer className="footer-pagecontent">
      <div className="footer-pagecontentbody">
        <div className="follow-us">
          <div className="img-tiktiki">
            <img src={images.common.tik} alt="tiktiki img" />
          </div>
          <h4>Follow Us</h4>
          <a href="https://www.facebook.com/ITagamaglobal?mibextid=ZbWKwL">
            <img src={images.layout.socialiconFacebook} alt="Facebook" />
          </a>
          <a href="https://twitter.com/ITagamaTM">
            <img src={images.layout.socialiconTwitter} alt="twitter" />
          </a>
          <a href="https://www.youtube.com/@itagama">
            <img src={images.layout.socialiconYoutube} alt="Youtube" />
          </a>
          <a href="https://www.linkedin.com/company/itagama/">
            <img src={images.layout.socialiconLinkedine} alt="Linkedine" />
          </a>
          <a href="https://www.instagram.com/itagama_tm">
            <img src={images.layout.socialiconInstagram} alt="Instagram" />
          </a>
        </div>
        <div className="copyright-textmessage">
          <p>
            Copyright @ 2024, Itagama.com or its affiliates. All rights
            reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default React.memo(Footer);
