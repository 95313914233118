import React, { createContext, useReducer } from 'react'

import initialState from './data.state';
import dataReducer from './data.reducer';
import {
  RoleApi,
  UserApi,
} from "../../api";
import {
  UPDATE_USER_CONTEXT,
  UPDATE_ROLE_CONTEXT,
} from './data.types';

export const DataContext = createContext<any>(initialState);

export const DataProvider = ({ children }) => {
  const [state, dispatchData] = useReducer(dataReducer, initialState);

  const loadUser = async () => {
    const payload = {
      '$select': 'id,first_name,last_name,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'first_name asc',
    }
    UserApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_USER_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  const loadRole = async () => {
    const payload = {
      '$select': 'id,code,name,status',
      /*'$filter': 'status=1',*/
      '$orderby': 'name asc',
    }
    RoleApi.list(payload)
        .then(res => {
          dispatchData({
            type: UPDATE_ROLE_CONTEXT,
            payload: {
              items: res.data.results,
              isLoading: false,
              isLoaded: true,
              totalCount: res.data.results.length,
            }
          });
        });
  }

  return (
    <DataContext.Provider value={{
      ...state,
      loadUser,
      loadRole,
    }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
