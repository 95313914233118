import companyLogo from "./company-logo.png";
import groupLogo from "./grouplogo.png";
import adminloginLogo from "./adminlogin-logo.png";
import headerSampleOne from "./headerSampleOne.png";
import socialiconFacebook from "./socialicon/facebook.png";
import socialiconTwitter from "./socialicon/twitter.png";
import socialiconGoogleplus from "./socialicon/google-plus.png";
import socialiconYoutube from "./socialicon/youtube.png";
import socialiconLinkedine from "./socialicon/linkedin.png";
import socialiconInstagram from "./socialicon/instagram.png";
import send from "./icon/svg/send.svg";
import dashboardUser from "./icon/svg/user.svg";
import dashboardPost from "./icon/svg/post.svg";
import dashboardChat from "./icon/svg/chat.svg";
import leftArrow from "./icon/baseline-keyboard_arrow_down-1.png";
import rightArrow from "./icon/baseline-keyboard_arrow_down-24px.png";
// Trade
import chainItemTwo from "./chain-item/chain-item-two.png";
import chainItemMachine from "./chain-item/machine.png";
import chainItemChainitemthree from "./chain-item/chain-item-three.png";
import chainItemChainitemOne from "./chain-item/chain-item-one.png";
import chainItemBlueprint from "./chain-item/blueprint.png";
import chainItemConsultant from "./chain-item/consultant.png";
import chainItemClothes from "./chain-item/clothes.png";
import chainItemKnowledge from "./chain-item/knowledge.png";
import chainItemBox from "./chain-item/box.png";
import authorinfo from "./authorinfo.png";
import SpinnerGif from "./spinner.gif";
import brainPicture from "./brainpicture.png";
import yarnCotton from "./yarn-cotton.png";
import ratingSample from "./rating-sample.png";
import ellipse_2 from "./Ellipse_2.png";

import gridViewIcon from "./icon/grid-view-icon.png";
import gridViewIconActive from "./icon/grid-view-icon-active.png";
import listViewIcon from "./icon/list-view-icon.png";
import listViewIconActive from "./icon/list-view-icon-active.png";

import invitePeopleIcon from "./icon/invite-people-icon.png";
import messageIcon from "./icon/message-icon.png";
import notificationIcon from "./icon/notification-icon.png";

import ImgLazyLoading from "./placeholder/img-lazy-loading.svg";
import ImageNotFound from "./placeholder/image-not-found.jpg";
import cottonImage from "./cotton.png";

import buyerQueryImage from "./buyer-query.png";
// buyer-query.png
import titikiImage from "./img-tit.gif";
import titikiImagesec from "./titikiImagesec.gif";
import titikiImagethird from "./tiktikithird.png";
import profilePicture from "./profile-pic.png";
import twitterIcon from "./icon/twittericon.png";
import Dashboardimgone from "./oneimg.png";
import Dashboardimgtwo from "./twoimg.png";
import susbg from "./susbg.jpg";
import deleteIcon from "./delete.png";
import editIcon from "./pencil.png";

import tik from "./main/tik.png";
// import copyright from './copyright.png';

const images = {
  icons: {
    SpinnerGif,
    gridViewIcon,
    gridViewIconActive,
    listViewIcon,
    listViewIconActive,
    invitePeopleIcon,
    messageIcon,
    notificationIcon,
    twitterIcon,
    send,
    dashboardUser,
    dashboardPost,
    dashboardChat,
    leftArrow,
    rightArrow,
    deleteIcon,
    editIcon,
  },
  placeholder: {
    ImgLazyLoading,
    ImageNotFound,
  },
  layout: {
    companyLogo,
    groupLogo,
    authorinfo,
    headerSampleOne,
    socialiconFacebook,
    socialiconTwitter,
    socialiconGoogleplus,
    socialiconYoutube,
    socialiconLinkedine,
    socialiconInstagram,
    adminloginLogo,
    brainPicture,
    yarnCotton,
    ratingSample,
  },
  trade: {
    chainItemTwo,
    chainItemMachine,
    chainItemChainitemthree,
    chainItemChainitemOne,
    chainItemBlueprint,
    chainItemConsultant,
    chainItemClothes,
    chainItemKnowledge,
    chainItemBox,
    cottonImage,
    buyerQueryImage,
    ellipse_2,
  },
  common: {
    titikiImage,
    titikiImagesec,
    titikiImagethird,
    profilePicture,
    Dashboardimgone,
    Dashboardimgtwo,
    susbg,
    tik
  },
  assets: {},
};

export default images;
