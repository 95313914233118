import {AxiosPromise} from "axios";
import {CONSTANT_CONFIG} from "../../constants";
import { HttpService } from "../../services/http.services";

const RESOURCE_ENDPOINT = `${CONSTANT_CONFIG.CORE_SERVER_PREFIX}/mail`
const endpoints = {
    sendRegisterMail: () => `${RESOURCE_ENDPOINT}/send-register-mail`,
    sendForgotPasswordMail: () => `${RESOURCE_ENDPOINT}/send-forgot-password-mail`,
    sendResetPasswordMail: () => `${RESOURCE_ENDPOINT}/send-reset-password-mail`,
    sendInvitePeopleMail: () => `${RESOURCE_ENDPOINT}/send-invite-people-mail`,
}

export default class SampleApi {
    public sendRegisterMail = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.sendRegisterMail();
        return HttpService.post(url, payload, params, headers);
    }

    public sendForgotPasswordMail = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.sendForgotPasswordMail();
        return HttpService.post(url, payload, params, headers);
    }

    public sendResetPasswordMail = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.sendResetPasswordMail();
        return HttpService.post(url, payload, params, headers);
    }

    public sendInvitePeopleMail = (payload = {}, params = {}, headers = {}): AxiosPromise<any> => {
        const url = endpoints.sendInvitePeopleMail();
        return HttpService.post(url, payload, params, headers);
    }
}