import * as React from "react";

const GoogleTranslate: React.FC<any> = ({
  ref = null,
  className = "",
  onClick = (e) => {},
}) => {
  React.useEffect(() => {
    var script = document.createElement("script");
    script.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    document.body.appendChild(script);

    window["googleTranslateElementInit"] = () => {
      new window["google"].translate.TranslateElement(
        {
          pageLanguage: "en",
          includedLanguages:
            "en,fr,es,vi,th,de,el,it,pt,zh-CN,sv,ar,bn,zh,hi,id,ja,ko,ms,ur,ru",
          
          layout:
            window["google"].translate.TranslateElement.InlineLayout.HORIZONTAL,
          autoDisplay: false,
        },
        "google_translate_element"
      );
    };
  }, []);

  return <div id="google_translate_element" />;
};

export default GoogleTranslate;
// English
// French
// German
// Greek
// Italian
// Portuguese
// Spanish
// Arabic
// Bengali
// Chinese (Mandarin)
// Hindi
// Indonesian
// Japanese
// Korean
// Malay
// Thai
// Urdu
// Vietnamese
// Russian
