const initialState = {
  isLoading: false,
  isLoaded: false,
  userContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  roleContext: {
    items: [],
    isLoading: true,
    isLoaded: false,
    totalCount: 0,
    error: null,
  },
  error: null,

  loadUser: () => null,
  loadRole: () => null,
};

export default initialState;
