import React from "react";
import { DashboardOutlined } from '@ant-design/icons';

export const UploadSidebarMenu = [
    {
        type: 'item',
        title: 'Fiber',
        permission: '',
        link: { to: '/upload/trade/fiber', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: <DashboardOutlined />,
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Machinery',
        permission: '',
        link: { to: '/upload/trade/machine', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Yarn',
        permission: '',
        link: { to: '/upload/trade/yarn', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Textile',
        permission: '',
        link: { to: '/upload/trade/textile', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Designers <br /> & <br /> Consultants',
        permission: '',
        link: { to: '/upload/trade/portfolio?type=1', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Garments <br /> Online Mall',
        permission: '',
        link: { to: '/upload/trade/garment', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Market <br /> Knowledge',
        permission: '',
        link: { to: '/upload/trade/market-knowledge', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Jobs',
        permission: '',
        link: { to: '/upload/trade/job?type=1', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
    {
        type: 'item',
        title: 'Bulk <br /> Order',
        permission: '',
        link: { to: '/upload/shop/bulk-order', exactMatch: true, externalUrl: false, openInNewTab: false },
        icon: '',
        class: '',
        badge: '',
        badgeClass: '',
        hidden: false,
    },
];
