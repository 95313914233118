import React, {FC, useContext, useEffect, useState} from 'react'
import NotificationDropdown from './Notification.dropdown';
import {SocketService} from "../../../../services";
import {AuthContext} from "../../../../context/auth/auth.context";
import {NotificationApi} from "../../../../api";
import '../../styles/Common.module.scss';
import './Notification.style.scss';

const initialState  = {
    visible: false,
    countBadge: 0,
    listData: [],
    loading: false,
    fields: {},
}

const Notification: FC<any> = props => {
    const { userId } = useContext(AuthContext)

    const [visible, setVisible] = useState(initialState.visible);
    const [countBadge, setCountBadge] = useState(initialState.countBadge);
    const [listData, setListData] = useState<any[]>(initialState.listData);
    const [loading, setLoading] = useState(initialState.loading);

    useEffect(() => {
        if (userId) {
            SocketService.subscribe('event_notification', handleNotificationWebSocketEvent);
        }

        return () => {
            SocketService.unsubscribe('event_notification');
         };
    }, [userId])

    useEffect(() => {
        if (userId) {
            badgeNotificationCount();
        }
    }, [userId])

    useEffect(() => {
        if (countBadge) {
            loadData();
        }
    }, [countBadge])

    const badgeNotificationCount = (): void => {
        setLoading(true);
        NotificationApi.countBadge(userId)
            .then(res => {
                setCountBadge(res.data);
                setLoading(false);
            });
    }

    const loadData = (): void => {
        setLoading(true);
        const payload = {
            '$filter': 'receiver_id eq ' + userId,
            '$orderby': 'is_read asc,id desc',
            '$top': countBadge > 5 ? countBadge : 5,
        }
        NotificationApi.list(payload)
            .then(res => {
                setListData(res.data.results);
                setLoading(false);
            });
    }

    const handleNotificationWebSocketEvent = (res: any) => {
        if (res.receiver_id && res.receiver_id == userId) {
            setCountBadge(prevCountBadge =>  prevCountBadge + 1);
        }
    };

    const handleVisibleChange = () => {
        setVisible(!visible);
    };

    const handleScroll = e => {
        /*const element = e.srcElement;
        if (element.scrollTop === element.scrollHeight - 474) {
            if (!initLoading && !loading && hasMore)
                dispatch({
                    type: 'notifications/more'
                });
        }*/
    }

    return (
        <NotificationDropdown
            visible={visible}
            listData={listData}
            loading={loading}
            countBadge={countBadge}
            handleVisibleChange ={handleVisibleChange}
            handleScroll ={handleScroll}
        />
    );
}

export default React.memo(Notification);
