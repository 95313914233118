import React, { ReactNode } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import LeftSidebar from "../../components/LeftSidebar/LeftSidebar";
import '../../../../assets/scss/style.scss';
import './AdminLayout.scss';

interface Props {
    children: ReactNode;
    title?: string;
}

export const AdminLayout: React.FC<Props> = (props: Props) => {
    return (
        <div className="app-wrapper app-admin-wrapper admin-dashboard-wrapper">
            <Header />
            <div className="main-layout">
                <LeftSidebar />
                <div className="content-wrapper">
                    <div className="main-content">
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}