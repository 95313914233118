// CDN Server
import _FileApi from './CdnServerApi/File.api';
import _ImageApi from './CdnServerApi/Image.api';

// Auth Server
import _AppsModuleApi from './AuthServerApi/AppsModule.api';
import _GroupApi from './AuthServerApi/Group.api';
import _OauthApi from './AuthServerApi/Oauth.api';
import _OrganizationApi from './AuthServerApi/Organization.api';
import _PermissionApi from './AuthServerApi/Permission.api';
import _RegisterSupplierApi from './AuthServerApi/RegisterSupplier.api';
import _ResourceApi from './AuthServerApi/Resource.api';
import _RoleApi from './AuthServerApi/Role.api';
import _ScopeApi from './AuthServerApi/Scope.api';
import _UserApi from './AuthServerApi/User.api';
import _WorkspaceApi from './AuthServerApi/Workspace.api';
import _AuthDashboardApi from './AuthServerApi/AuthDashboard.api';

// Core Server
import _ChatApi from './CoreServerApi/Chat.api';
import _ChatMessageApi from './CoreServerApi/ChatMessage.api';
import _ChatRoomApi from './CoreServerApi/ChatRoom.api';
import _ChatRoomMemberApi from './CoreServerApi/ChatRoomMember.api';
import _FollowApi from './CoreServerApi/Follow.api';
import _MailApi from './CoreServerApi/Mail.api';
import _NotificationApi from './CoreServerApi/Notification.api';
import _ReviewAndRatingApi from './CoreServerApi/ReviewAndRating.api';
import _OptionApi from './CoreServerApi/Option.api';
import _TagApi from './CoreServerApi/Tag.api';

// Trade Server
import _FiberApi from './TradeServerApi/Fiber.api';
import _GarmentApi from './TradeServerApi/Garment.api';
import _MachineApi from './TradeServerApi/Machine.api';
import _MarketKnowledgeApi from './TradeServerApi/MarketKnowledge.api';
import _SupplyChainApi from './TradeServerApi/SupplyChain.api';
import _TextileApi from './TradeServerApi/Textile.api';
import _YarnApi from './TradeServerApi/Yarn.api';
import _JobApi from './TradeServerApi/Job.api';
import _PortfolioApi from './TradeServerApi/Portfolio.api';
import _IndexChartApi from './TradeServerApi/IndexChart.api';
import _LogisticApi from './TradeServerApi/Logistic.api';
import _JobApplicantApi from './TradeServerApi/JobApplicant.api';
import _PostApi from './TradeServerApi/Post.api';
import _BulkOrderApi from './TradeServerApi/BulkOrder.api';
import _MallProductApi from './TradeServerApi/MallProduct.api';
import _ShopLinkApi from './TradeServerApi/ShopLink.api';
import _BuyerPostApi from './TradeServerApi/BuyerPost.api';
import _NodeApi from "./NodeServerApi/Node.api";
// CND Server
export const FileApi = new _FileApi();
export const ImageApi = new _ImageApi();

// Auth Server
export const AppsModuleApi = new _AppsModuleApi();
export const GroupApi = new _GroupApi();
export const OauthApi = new _OauthApi();
export const OrganizationApi = new _OrganizationApi();
export const PermissionApi = new _PermissionApi();
export const RegisterSupplierApi = new _RegisterSupplierApi();
export const ResourceApi = new _ResourceApi();
export const RoleApi = new _RoleApi();
export const ScopeApi = new _ScopeApi();
export const UserApi = new _UserApi();
export const WorkspaceApi = new _WorkspaceApi();
export const AuthDashboardApi = new _AuthDashboardApi();

// Core Server
export const ChatApi = new _ChatApi();
export const ChatMessageApi = new _ChatMessageApi();
export const ChatRoomApi = new _ChatRoomApi();
export const ChatRoomMemberApi = new _ChatRoomMemberApi();
export const FollowApi = new _FollowApi();
export const MailApi = new _MailApi();
export const OptionApi = new _OptionApi();
export const NotificationApi = new _NotificationApi();
export const ReviewAndRatingApi = new _ReviewAndRatingApi();
export const TagApi = new _TagApi();

// Trade Server
export const FiberApi = new _FiberApi();
export const GarmentApi = new _GarmentApi();
export const MachineApi = new _MachineApi();
export const MarketKnowledgeApi = new _MarketKnowledgeApi();
export const SupplyChainApi = new _SupplyChainApi();
export const TextileApi = new _TextileApi();
export const YarnApi = new _YarnApi();
export const JobApi = new _JobApi();
export const PortfolioApi = new _PortfolioApi();
export const IndexChartApi = new _IndexChartApi();
export const LogisticApi = new _LogisticApi();
export const JobApplicantApi = new _JobApplicantApi();
export const PostApi = new _PostApi();
export const BulkOrderApi = new _BulkOrderApi();
export const MallProductApi = new _MallProductApi();
export const ShopLinkApi = new _ShopLinkApi();
export const BuyerPostApi = new _BuyerPostApi();
export const NodeApi = new _NodeApi()