import React, { ReactNode } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import '../../../../assets/scss/style.scss';
import './MainLayout.scss';

interface Props {
    children: ReactNode;
    title?: string;
}

export const MainLayout: React.FC<Props> = (props: Props) => {
    return (
        <div className="app-wrapper">
            <Header />
            <div className="main-layout">
                <div className="content-wrapper">
                    <div className="main-content">
                        {props.children}
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}