import React, { FC, Fragment, useRef } from 'react'
import { Spin } from 'antd';
import { Scrollbars } from 'react-custom-scrollbars';
import { Avatar } from 'antd';
import {DateTimeUtils} from "../../../../utils";

const NotificationDropdownContent: FC<any> = props => {
    const scrollEleRef = useRef(null);
    const { listData, loading, countBadge, handleScroll } = props;
    return (
        <Fragment>

                <div className="notification-drop-down">
                    <div className="notification-header">
                        <h3>Notifications</h3>
                        {loading === true ? (<Spin size="small" spinning={loading} />) : <p>You have {countBadge} new notifications.</p>}
                    </div>
                    <div className="notification-content">
                        <Scrollbars ref={scrollEleRef} autoHeight autoHeightMax={474} onScroll={handleScroll} className="scrollEle">
                        { listData?.map((item, index) => (
                            <div key={index} className="notification-item">
                                <div className="notification-item-icon">
                                    <Avatar style={{ backgroundColor: '#1dbf74', verticalAlign: 'middle' }} size="large">U</Avatar>
                                </div>
                                <div className="notification-item-text">
                                    <span className="name">{item.message}</span>
                                    <span className="time">{DateTimeUtils.timeAgo(item.created_at)}</span>
                                </div>
                            </div>
                        )) }
                        </Scrollbars>
                    </div>
                    <div className="notification-footer">
                       <p>Mark all as read </p>
                    </div>
                </div>

        </Fragment>
    );
}
export default React.memo(NotificationDropdownContent);