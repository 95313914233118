import React, { FC, Fragment } from 'react'
import { Popover, Badge } from 'antd';
import NotificationDropdownContent from "./Notification.dropdown.content";
import images from "../../../../assets/images";

const NotificationDropdown: FC<any> = props => {
    const { visible, loading, listData, countBadge, handleVisibleChange, handleScroll } = props;
    return (
        <div className="esNotification">  
            <Popover
                placement="bottomRight"
                content={<NotificationDropdownContent handleScroll={handleScroll} listData={listData} loading={loading} countBadge={countBadge} />}
                overlayClassName="top-bar-notification cbNotification"
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <span className="notification-icon">
                    <Badge
                        count={countBadge}
                        style={{ boxShadow: 'none' }}
                        className="notification-badge"
                        overflowCount={99}
                    >
                        <img src={images.icons.notificationIcon} alt="notificationIcon" />
                    </Badge>
                </span>
            </Popover>
        </div>
    );
}
export default React.memo(NotificationDropdown);