import React, {FC, useEffect, useState} from 'react'
import { NavLink } from 'react-router-dom';
import {UploadSidebarMenu} from "./UploadSidebar.menu";
import {usePermissionContext} from "../../../../hooks/context/usePermissionContext";
import './UploadSidebar.style.scss';

const UploadSidebar: FC<any> = props => {
    const { isPermissionLoaded, hasPermission } = usePermissionContext();
    const [navigationList, setNavigationList] = useState<any>(UploadSidebarMenu);

    useEffect(() => {
        if (isPermissionLoaded) {
            const leftMenuList = UploadSidebarMenu.filter((item: any) => {
                return hasPermission(item?.permission)
            })
           setNavigationList(leftMenuList);
        }
    }, [isPermissionLoaded])

    return (
        <ul>
            {navigationList?.map((item: any, index: number) => (
                <li key={index}>
                    <NavLink activeClassName="active" exact to={item.link.to} dangerouslySetInnerHTML={{ __html: item.title }}></NavLink>
                </li>
            ))}
        </ul>
    );
}

export default React.memo(UploadSidebar);