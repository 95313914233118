import React, { createContext, useReducer, useEffect } from "react";

import initialState from "./auth.state";
import authReducer from "./auth.reducer";
import {
  CLEAR_ALL_AUTH_STATE,
  LOADED_GROUP,
  LOADED_ROLE,
  LOADED_WORKSPACE,
  LOADED_ORGANIZATION,
  LOADED_AUTH_STATE,
  LOADED_SCOPE,
  CLEAR_AUTH_STATE,
  LOADED_SUPPLIER_REGISTER_ENTITY,
} from "./auth.types";
import { HttpService } from "../../services/http.services";
import { StorageService } from "../../services";
import { OauthApi } from "../../api";

export const AuthContext = createContext(initialState);

export const AuthProvider = ({ children }) => {
  const [state, dispatchAuth] = useReducer(authReducer, initialState);

  useEffect(() => {
    loadAuthState();
  }, []);

  const loadAuthState = async () => {
    try {
      const accessToken = await StorageService.getAccessToken();
      if (accessToken) {
        HttpService.setAccessToken(accessToken);
      } else {
        throw new Error("Error loading auth state");
      }

      OauthApi.loadAuthState()
        .then((res) => {
          // User
          console.log(res.data, "golam")
          dispatchAuth({
            type: LOADED_AUTH_STATE,
            payload: {
              user: res.data.user,
              profileImage: res.data.profileImage,
              userId: res.data.userId,
            },
          });

          // Group
          dispatchAuth({
            type: LOADED_GROUP,
            payload: {
              userGroup: res.data.userGroup,
              groupId: res.data.groupId,
              group: res.data.group,
            },
          });

          // Role
          dispatchAuth({
            type: LOADED_ROLE,
            payload: {
              userRole: res.data.userRole,
              roleId: res.data.roleId,
              role: res.data.role,
            },
          });

          // Scopes
          dispatchAuth({
            type: LOADED_SCOPE,
            payload: {
              scopes:  res.data.scopes,
            },
          });

          // Workspace
          dispatchAuth({
            type: LOADED_WORKSPACE,
            payload: {
              workspaceId: res.data.workspaceId,
              workspace: res.data.workspace,
            },
          });

          // Organization
          dispatchAuth({
            type: LOADED_ORGANIZATION,
            payload: {
              organizationId: res.data.organizationId,
              organization: res.data.organization,
            },
          });

          // Register Supplier
          dispatchAuth({
            type: LOADED_SUPPLIER_REGISTER_ENTITY,
            payload: {
              supplierRegisterEntity: res.data.registerSupplier,
              isLoadSupplierRegisterEntity: true,
            },
          });
        })
        .catch((err) => {
          dispatchAuth({ type: CLEAR_ALL_AUTH_STATE });
          dispatchAuth({ type: CLEAR_AUTH_STATE });
        });
    } catch (err) {
      dispatchAuth({ type: CLEAR_ALL_AUTH_STATE });
      dispatchAuth({ type: CLEAR_AUTH_STATE });
    }
  };

  const clearAuthState = () => {
    dispatchAuth({ type: CLEAR_ALL_AUTH_STATE });
    dispatchAuth({ type: CLEAR_AUTH_STATE });
  };

  const setLoading = (isShow: boolean): void => {
    if (isShow) {
      dispatchAuth({
        type: "SET_LOADING",
        payload: {
          loading: true,
        },
      });
    } else {
      dispatchAuth({
        type: "SET_LOADING",
        payload: {
          loading: false,
        },
      });
    }
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        dispatchAuth,
        loadAuthState,
        clearAuthState,
        setLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
