import React, { Suspense, useEffect } from 'react';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import Routes from './routes/Routes';

import store from './store/store';
import ContextProvider from './context';
import Loader from './components/Loading/Loader';
import LazyLoader from './components/Loading/LazyLoader';
import { ErrorBoundary } from './components/ErrorBoundary/ErrorBoundary';
import './app.scss';
function App() {
  




  return (
    <ContextProvider>
      <StoreProvider store={store}>
        <BrowserRouter>
          <Suspense fallback={<LazyLoader />}>
            <Loader />
            <ErrorBoundary>
              <Routes />
            </ErrorBoundary>
          </Suspense>
        </BrowserRouter>
      </StoreProvider>
    </ContextProvider>
  );
}

export default App;
