import io from 'socket.io-client';
import {CONSTANT_CONFIG} from "../constants";

export default class SocketService {

    private instance: any = null;

    private socketUrl: string = `${CONSTANT_CONFIG.WEB_SOCKET_URL}`;

    private socket: any;

    constructor() {
        if (!this.instance) {
            this.socket = io(this.socketUrl, {
                secure: false // for SSL
            });
            this.instance = this;
        }
        return this.instance
    }

    public connect() {
        return this.socket.connect();
    }

    public getSocket(){
        return this.socket;
    }

    public on(eventName: string, callback: Function): void {
        this.socket && this.socket.on(eventName, callback);
    }

    public of(namespace: string): void {
        this.socket && this.socket.of(namespace);
    }

    public once(eventName: string, callback: Function) {
        this.socket && this.socket.once(eventName, callback);
    }

    public emit(eventName: string, payload: any, callback?: Function): void {
        if (callback) {
            this.socket && this.socket.emit('ClientServerClientEmit', {eventName: eventName, payload: payload}, callback);
        }
        else {
            this.socket && this.socket.emit('ClientServerClientEmit', {eventName: eventName, payload: payload});
        }
    }

    public broadcast(eventName: string, payload: any, callback?: Function): void {
        if (callback) {
            this.socket && this.socket.emit('ClientServerClientBroadcast', {eventName: eventName, payload: payload}, callback);
        }
        else {
            this.socket && this.socket.emit('ClientServerClientBroadcast', {eventName: eventName, payload: payload});
        }
    }

    public subscribe(eventName: string, callback: Function): void {
        this.socket && this.socket.on(eventName, callback);
    }

    public unsubscribe(eventName: string): void {
        this.socket && this.socket.off(eventName);
    }

    public isConnected(): boolean {
        return this.socket && this.socket.connected;
    }

    public reconnect() {
        if (!this.socket) { return false; }
        this.socket.disconnect();
        this.socket.connect();
    }

    public disconnect() {
        this.socket && this.socket.disconnect();
    }

}
