import React, { FC, useContext, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { Dropdown, Avatar, Menu, Badge } from "antd";
import { UserOutlined } from "@ant-design/icons";
import images from "../../../../assets/images";
import { AuthContext } from "../../../../context/auth/auth.context";
import ProfileMenu from "../../../../components/Menu/ProfileMenu/ProfileMenu";
import InvitePeople from "../../../../modules/Common/components/InvitePeople/InvitePeople.controller";
import Notification from "../../../../modules/Common/components/Notification/Notification.controller";
import Inbox from "../../../../modules/Common/components/Inbox/Inbox.controller";

import { CONSTANT_CONFIG } from "../../../../constants";
import GoogleTranslate from "../../../../components/GoogleTranslate/GoogleTranslate";
import { usePermissionContext } from "../../../../hooks/context/usePermissionContext";
import "./Header.style.scss";
import closeiMG from '../../../../assets/images/icon/svg/close.svg'
import Select, { ValueType } from "react-select";
// import { ValueType } from 'react-select/lib/types';


const Header: FC<any> = (props) => {
  const { isPermissionLoaded, hasPermission } = usePermissionContext();
  const { profileImage, isAuthenticated, user, userId } = useContext(
    AuthContext
  );

  

  //===== [MOBILE LANGUAGE SWITCHER START ] =====//
  type OptionType = {
    value: string;
    label: string;
  };
  const options : OptionType[] = [
    { value: 'chocolate', label: 'Chocolate' },
    { value: 'strawberry', label: 'Strawberry' },
    { value: 'vanilla', label: 'Vanilla' },
  ];
  const [selectedOption, setSelectedOption] = useState<ValueType<OptionType, false>>(options[0]);
  //===== [MOBILE LANGUAGE SWITCHER START ] =====//

  const { pathname } = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  console.log(window?.innerWidth )
  return (
    
    <>
      <div className="main-menu">
        <header className="header-pagecontent">
          <div className="header-pagecontentbody">
            {/*<div className="img-tiktiki">*/}
            {/*<img src={images.common.titikiImage} alt="tiktiki img" />*/}
            {/*</div>*/}
            <div className="company-logo">
              <Link to={"/"}>
                <img src={images.layout.companyLogo} alt="Company Logo" />
              </Link>
            </div>
            <div className={`menuWrapper ${sidebarOpen ? 'sidebarOpne' : ''}`}>
            <div className="main-menulist">
              <div className="close">
                  <div className="closeWrapper" onClick={()=>setSidebarOpen(false)}>
                    <img src={closeiMG} alt=""/>
                  </div>
                </div>
              <ul>
                
                <li>
                  <NavLink exact activeClassName="active" to={"/"} onClick={()=>setSidebarOpen(false)}>
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink exact activeClassName="active" to={"/page/about-us"} onClick={()=>setSidebarOpen(false)}>
                    About Us
                  </NavLink>
                </li>
              </ul>
            </div>

            {isAuthenticated ? (
              <>
                {/* <div className="header-right">
                  <div className="header-invite-people">
                    <InvitePeople />
                  </div>
                  <div className="header-inbox">
                    
                    <Link className="message-icon" to={"/apps/messenger"}>
                      <Badge
                        count={0}
                        style={{ boxShadow: "none" }}
                        className="message-badge"
                        overflowCount={99}
                      >
                        <img src={images.icons.messageIcon} alt="Message" />
                      </Badge>
                    </Link>
                  </div>
                  <div className="header-notification">
                    <Notification />
                  </div>
                </div> */}
                <div className="language">
                  <GoogleTranslate />
                </div>
                {/* <div className="header-user-profile">
                  <Dropdown
                    overlay={ProfileMenu(
                      userId,
                      isPermissionLoaded,
                      hasPermission
                    )}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    {profileImage ? (
                      <Avatar
                        size={45}
                        shape="circle"
                        src={CONSTANT_CONFIG.CDN_URL + "image/" + profileImage}
                        alt="Profile Pic"
                      />
                    ) : (
                      <Avatar size={45} icon={<UserOutlined />} />
                    )}
                  </Dropdown>
                </div> */}
              </>
            ) : (
              <div className="header-signin-btn">
                <NavLink exact activeClassName="active" to={"/auth/login"} onClick={()=>setSidebarOpen(false)}>
                  Sign in
                </NavLink>
              </div>
            )}
            </div>

            <div className="mobileOthersBar">
                <div className="header-right mobile">
                  <div className="header-invite-people">
                    <InvitePeople />
                  </div>
                  <div className="header-inbox">
                    {/*<Inbox />*/}
                    <Link className="message-icon" to={"/apps/messenger"}>
                      <Badge
                        count={0}
                        style={{ boxShadow: "none" }}
                        className="message-badge"
                        overflowCount={99}
                      >
                        <img src={images.icons.messageIcon} alt="Message" />
                      </Badge>
                    </Link>
                  </div>
                  <div className="header-notification withOutIsAathforMobile">
                    <Notification />
                  </div>
                </div>
            </div>
            <div className="mobileMenuBar">

                {/* <div className="dummyMobleLanguage">
                <Select
                  defaultValue={selectedOption}
                  onChange={option => setSelectedOption(option)}
                  options={options}
                  // defaultInputValue="Language"
                  isSearchable={false}
                  classNamePrefix="es"
                />
                </div> */}
                  
                {isAuthenticated && (
                  <div className="header-user-profile mobile">
                  <Dropdown
                    overlay={ProfileMenu(
                      userId,
                      isPermissionLoaded,
                      hasPermission
                    )}
                    trigger={["click"]}
                    placement="bottomRight"
                  >
                    {profileImage ? (
                      <Avatar
                        size={45}
                        shape="circle"
                        src={CONSTANT_CONFIG.CDN_URL + "image/" + profileImage}
                        alt="Profile Pic"
                      />
                    ) : (
                      <Avatar size={45} icon={<UserOutlined />} />
                    )}
                  </Dropdown>
                </div>
                ) }

                <div className="mobileBar"  onClick={()=>setSidebarOpen(!sidebarOpen)}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
              
            
          </div>
          <div className="mobileOthersBar small">
                <div className="header-right mobile">
                <div className="mobileMenuBar" onClick={()=>setSidebarOpen(!sidebarOpen)}>
                  <div className="mobileBar forMobile">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                  <div className="header-invite-people">
                    <InvitePeople />
                  </div>
                  <div className="header-inbox">
                    {/*<Inbox />*/}
                    <Link className="message-icon" to={"/apps/messenger"}>
                      <Badge
                        count={0}
                        style={{ boxShadow: "none" }}
                        className="message-badge"
                        overflowCount={99}
                      >
                        <img src={images.icons.messageIcon} alt="Message" />
                      </Badge>
                    </Link>
                  </div>
                  <div className="header-notification">
                    <Notification />
                  </div>
                </div>
                
                
            </div>
        </header>

        {pathname != "/" && (
          <div className="header-quick-menu">
            <Menu mode="horizontal">
              <Menu.Item key="1">
                <NavLink activeClassName="active" to={"/trade/fiber"}>
                  Fiber
                </NavLink>
              </Menu.Item>
              <Menu.Item key="2">
                <NavLink activeClassName="active" to={"/trade/machine"}>
                  Machinery
                </NavLink>
              </Menu.Item>
              <Menu.Item key="3">
                <NavLink activeClassName="active" to={"/trade/yarn"}>
                  Yarn
                </NavLink>
              </Menu.Item>
              <Menu.Item key="4">
                <NavLink activeClassName="active" to={"/trade/textile"}>
                  Textile
                </NavLink>
              </Menu.Item>

              <Menu.Item key="11">
                <NavLink activeClassName="active" to={"/trade/buyer-post"}>
                  Buy
                </NavLink>
              </Menu.Item>


              <Menu.Item key="5">
                <NavLink activeClassName="active" to={"/trade/design"}>
                  Design
                </NavLink>
              </Menu.Item>
              <Menu.Item key="6">
                <NavLink activeClassName="active" to={"/trade/consultant"}>
                  Consultant
                </NavLink>
              </Menu.Item>
              <Menu.Item key="7">
                <NavLink activeClassName="active" to={"/trade/job"}>
                  Job
                </NavLink>
              </Menu.Item>
              <Menu.Item key="8">
                <NavLink activeClassName="active" to={"/trade/garment"}>
                  Garment
                </NavLink>
              </Menu.Item>
              <Menu.Item key="9">
                <NavLink
                  activeClassName="active"
                  to={"/trade/market-knowledge"}
                >
                  Knowledge
                </NavLink>
              </Menu.Item>
              <Menu.Item key="10">
                <NavLink activeClassName="active" to={"/trade/logistic"}>
                  Logistic
                </NavLink>
              </Menu.Item>
              <Menu.Item key="13">
                <NavLink activeClassName="active" to={"/shop/shop-link"}>
                  Online Mall
                </NavLink>
              </Menu.Item>
             
              <Menu.Item key="12" className="group-logo group-logo-resize">
                <NavLink activeClassName="active" to={"/page/sustainable"}>
                  {" "}
                  <img src={images.layout.groupLogo} alt="Group Logo" />
                </NavLink>
              </Menu.Item>
            </Menu>
          </div>
        )}
      </div>
    </>
  );
};

export default React.memo(Header);
